

































































































































































































































import ViewModel from '@/views/service/Index.ts';
export default ViewModel;
